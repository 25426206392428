import { createChatBotMessage } from 'react-chatbot-kit'
import Options from "./Options"
import RenderImage from "./RenderImage"

const config = {
  initialMessages: [],
  botName: "client",
  customStyles: {
    botMessageBox: {
      backgroundColor: '#376B7E',
    },
    chatButton: {
      backgroundColor: '#5ccc9d',
    },
  },
  widgets: [
    {
      widgetName: 'optionSelect',
      widgetFunc: (props) => <Options {...props} />
    },
    {
      widgetName: 'renderImage',
      widgetFunc: (props) => <RenderImage {...props} />
    }
  ],
}

export default config
