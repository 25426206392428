// let api = "https://wachatbot.vibhohcm.com/api"
let api = "https://pocwhatsapp.vibhohcm.com"

// if (process.env.NODE_ENV === "development") {
//     api = "http://localhost:5010/api"
// } else {
//     api = "https://wachatbot.vibhohcm.com/api"
// }

export default api
