import axios from "axios"
import api from "../config/backend"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

const Login = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const navigate = useNavigate()

    const handleLogin = async () => {
        const { data, status } = await axios.post(`${api}/Agent/sign-in`, { email, password })

        if (data.success) {
            localStorage.setItem("ca-token", data.token)
            navigate("/dashboard")
        }
        else {
            console.log(data.message)
        }
    }

    return (
        <div style={{ margin: "20px" }}>
            <div style={{ fontSize: "14px", color: "red" }}>Note: This page is not meant to be tested</div>

            <div style={{ display: "flex", flexDirection: "column", gap: "10px", marginTop: "20px", width: "400px" }}>
                <input placeholder="Please enter e-mail" value={email} onChange={e => setEmail(e.target.value)} />
                <input placeholder="Please enter password" type="password" value={password} onChange={e => setPassword(e.target.value)} />
                <button style={{ width: "100px" }} onClick={() => handleLogin()}>Login</button>
            </div>
        </div>
    )
}

export default Login
